import { useEffect } from "react";
import "./App.css";
import Login from "./components/login";
import { useDispatch, useSelector } from "react-redux";
import { login, setGameId, setUserId } from "./redux/reducer/app-slice";
import Game from "./components/game";

function App() {
  const accessToken = useSelector((state) => state.app.accessToken);
  const gameId = useSelector((state) => state.app.gameId);
  const dispatch = useDispatch();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const accessToken = queryParams.get("access_token");
    const gameId = queryParams.get("game_id");
    const userId = queryParams.get("user_id");

    if (accessToken) {
      dispatch(login(accessToken));
    }
    if (gameId) {
      dispatch(setGameId(gameId));
    }
    if (userId) {
      dispatch(setUserId(userId));
    }
  }, []);

  return (
    <div className="App">
      <h1>Björns Songs</h1>
      {!accessToken ? <Login /> : <Game token={accessToken} gameId={gameId} />}
    </div>
  );
}

export default App;
