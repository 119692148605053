import React from "react";
import WebPlayer from "./web-player";
import SelectGame from "./select-game";

const Game = ({ token, gameId }) => {
  return token && gameId ? (
    <WebPlayer token={token} gameId={gameId} />
  ) : (
    <SelectGame token={token} />
  );
};
export default Game;
