import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getGameData, markSongAsPlayed } from "../redux/reducer/app-slice";

const trackModel = {
  name: "",
  album: {
    images: [{ url: "" }],
  },
  artists: [{ name: "" }],
  id: "",
};

const WebPlayer = ({ token }) => {
  const [player, setPlayer] = useState(undefined);
  const [track, setTrack] = useState(trackModel);
  const [playing, setPlaying] = useState(false);
  const [deviceId, setDeviceId] = useState(undefined);
  const [gameStarted, setGameStarted] = useState(false);
  const [isRevealed, setIsRevealed] = useState(false);
  const [currentSong, setCurrentSong] = useState(null);

  const dispatch = useDispatch();

  const gameData = useSelector((state) => state.app.gameData);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://sdk.scdn.co/spotify-player.js";
    script.async = true;

    document.body.appendChild(script);

    window.onSpotifyWebPlaybackSDKReady = () => {
      const player = new window.Spotify.Player({
        name: "Web Playback SDK",
        getOAuthToken: (cb) => {
          cb(token);
        },
        volume: 0.5,
      });

      setPlayer(player);

      player.addListener("ready", ({ device_id }) => {
        console.log("Ready with Device ID", device_id);
        setDeviceId(device_id);
        transferPlayback();
      });

      player.addListener("not_ready", ({ device_id }) => {
        console.log("Device ID has gone offline", device_id);
      });

      player.addListener("player_state_changed", (state) => {
        if (!state) {
          return;
        }

        console.log("player state changed");
        setTrack(state.track_window.current_track);

        // player.getCurrentState().then((state) => {
        //   !state ? setActive(false) : setActive(true);
        // });
      });

      player.connect();
    };
  }, []);

  useEffect(() => {
    dispatch(getGameData());
  }, []);

  useEffect(() => {
    setCurrentSong(
      gameData?.shuffledSongs?.find((song) => song.spotifyTrackId === track?.id)
    );
  }, [gameData, track]);

  const playNextUnplayedSong = () => {
    const firstUnplayedSong = gameData.shuffledSongs.find(
      (song) => song.listenedTo === 0
    );
    pausePlayPlayback("play", firstUnplayedSong.spotifyTrackId);
  };

  const handleStartGame = () => {
    setGameStarted(true);
    playNextUnplayedSong();
    setPlaying(true);
  };

  const transferPlayback = async () => {
    if (deviceId && token) {
      fetch(`https://api.spotify.com/v1/me/player`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ device_ids: [deviceId], play: false }),
      });
    }
  };

  const pausePlayPlayback = (pauseOrPlay, trackId) => {
    if (deviceId) {
      const options = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        method: "PUT",
      };
      if (trackId) {
        options.body = JSON.stringify({ uris: [`spotify:track:${trackId}`] });
      }
      fetch(
        `https://api.spotify.com/v1/me/player/${pauseOrPlay}?device_id=${deviceId}`,
        options
      );
    }
  };

  const togglePlayPause = () => {
    if (playing) {
      pausePlayPlayback("pause");
      setPlaying(false);
    } else {
      pausePlayPlayback("play");
      setPlaying(true);
    }
  };

  const allSongs = () => {
    if (gameData && gameData.shuffledSongs) {
      return gameData.shuffledSongs.length;
    }
    return "...";
  };

  const playedSongs = () => {
    if (gameData && gameData.shuffledSongs) {
      return gameData.shuffledSongs.filter((song) => song.listenedTo === 1)
        .length;
    }
    return "...";
  };

  const handleReveal = () => {
    const currentGameSongId = currentSong.gameSongId;
    dispatch(markSongAsPlayed(currentGameSongId));
    setIsRevealed(true);
    console.log(currentGameSongId);
  };

  return (
    <>
      <div className="container mt-5">
        <div className="card p-3" id="player-container">
          <h2>
            Song {playedSongs() + 1} of {allSongs()}
          </h2>
          <div
            className="row mb-4"
            id="song-info"
            style={{ position: "relative" }}
          >
            <div className="col-md-4">
              <img
                src={`${track?.album?.images[0]?.url}`}
                className="img-fluid rounded"
                id="album-art"
                alt="Album Art"
              />
            </div>
            <div className="col-md-8">
              <h5 className="text-truncate" id="song-title">
                {currentSong?.trackName || " Song Title"}
              </h5>
              <p className="text-muted" id="artist-name">
                {currentSong?.artistName.replace(/,/g, ", ") || "Artist Name"}
              </p>
              <div
                className=""
                id="release-year"
                style={{ fontWeight: "bold", fontSize: "36px" }}
              >
                {currentSong?.releaseYear || "Release Year"}
              </div>
            </div>
            <div
              id="revealBlock"
              style={
                !isRevealed
                  ? {
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      backgroundColor: "#ffffff",
                    }
                  : {
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      backgroundColor: "transparent",
                      transition: "all 0.5s",
                    }
              }
            ></div>
          </div>

          <div className="row mb-3">
            {!gameStarted ? (
              <button
                className="btn btn-primary btn-block"
                id="btn-startGame"
                onClick={() => handleStartGame()}
              >
                Start
              </button>
            ) : (
              <div className="col d-flex justify-content-between align-items-center">
                <button
                  className="btn btn-outline-secondary"
                  id="btn-previous"
                  disabled
                >
                  Prev
                </button>
                <button
                  className="btn btn-primary"
                  id="btn-playOrPause"
                  onClick={() => togglePlayPause()}
                >
                  {playing ? "Pause" : "Play"}
                </button>
                <button
                  className="btn btn-outline-secondary"
                  id="btn-next"
                  onClick={() => {
                    playNextUnplayedSong();
                    setIsRevealed(false);
                  }}
                  disabled={!isRevealed}
                >
                  Next
                </button>
              </div>
            )}
          </div>

          <div className="row mb-3" id="progress-bar-container">
            <div className="col">
              <input
                type="range"
                className="form-range"
                id="progress-bar"
                value="0"
                min="0"
                max="100"
              />
              <div className="d-flex justify-content-between">
                <span id="current-time">0:00</span>
                <span id="total-duration">3:45</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-5">
        <div className="row mb-3">
          <button
            className="btn btn-success btn-block btn-lg"
            id="btn-reveal"
            onClick={() => handleReveal()}
            disabled={!gameStarted || isRevealed}
          >
            Reveal
          </button>
        </div>
      </div>
    </>
  );
};

export default WebPlayer;
