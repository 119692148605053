import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchGames } from "../redux/reducer/app-slice";

const SelectGame = ({ token }) => {
  const dispatch = useDispatch();
  const games = useSelector((state) => state.app.games);
  const userId = useSelector((state) => state.app.userId);
  useEffect(() => {
    dispatch(fetchGames());
  }, []);

  return (
    <>
      <div className="container mt-5">
        <h1 className="text-center mb-4">Setup</h1>
        <a
          href={`${process.env.REACT_APP_SERVER_HOST}/api/new-game/?userId=${userId}`}
          role="button"
          className="btn btn-primary mt-3"
        >
          Start New Game
        </a>
      </div>

      <div className="container mt-5">
        <h1 className="mb-4">Ongoing Games</h1>
        <ul id="gamesList" className="list-group">
          {games &&
            games.map(({ id, name, status, totalPlayedSongs, totalSongs }) => (
              <li
                key={id}
                className="list-group-item d-flex justify-content-between align-items-center"
              >
                <a
                  href={`/?game_id=${id}&user_id=${userId}&access_token=${token}`}
                  className="stretched-link"
                >
                  {name}{" "}
                  <span>
                    {totalPlayedSongs} / {totalSongs} songs played
                  </span>
                </a>
              </li>
            ))}
        </ul>
      </div>
    </>
  );
};

export default SelectGame;
