import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  accessToken: null,
  deviceId: null,
  userName: null,
  userId: null,
  gameId: null,
  gameData: null,
  games: null,
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    login: (state, action) => {
      state.accessToken = action.payload;
      console.log("AT successfully stored");
    },
    logout: () => initialState,
    setGames: (state, action) => {
      state.games = action.payload;
    },
    setGameId: (state, action) => {
      state.gameId = action.payload;
    },
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    setGameData: (state, action) => {
      state.gameData = action.payload;
    },
  },
});

export const getGameData = () => (dispatch, getState) => {
  const accessToken = selectAccessToken(getState());
  const gameId = selectGameId(getState());
  if (accessToken && gameId) {
    fetch(`${process.env.REACT_APP_SERVER_HOST}/api/games/${gameId}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((gameData) => {
        console.log("game Data:", gameData);
        dispatch(setGameData(gameData));
      });
    //     fetch(
    //       `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${accessToken}`,
    //       {
    //         headers: {
    //           Authorization: `Bearer ${accessToken}`,
    //           Accept: "application/json",
    //         },
    //       }
    //     )
    //       .then((response) => {
    //         if (!response.ok) {
    //           throw new Error(response);
    //         }
    //         return response.json();
    //       })
    //       .then((profile) => {
    //         dispatch(setIsCheckingLoginState(false));
    //       })
    //       .catch((error) => {
    //         dispatch(logoutAll());
    //         dispatch(setIsCheckingLoginState(false));
    //       });
    //   } else {
    //     dispatch(logoutAll());
    //     dispatch(setIsCheckingLoginState(false));
  }
};

export const fetchGames = () => (dispatch) => {
  fetch(`${process.env.REACT_APP_SERVER_HOST}/api/games/`)
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      console.log(response);
      return response.json();
    })
    .then((games) => {
      dispatch(setGames(games));
    });
};

export const startNewGame = () => (dispatch, getState) => {
  const userId = selectUserId(getState());
  fetch(`${process.env.REACT_APP_SERVER_HOST}/api/games/?userId=${userId}`, {
    method: "post",
  }).then((response) => {
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
  });
};
export const markSongAsPlayed = (gameSondId) => (dispatch) => {
  fetch(`${process.env.REACT_APP_SERVER_HOST}/api/song-played/${gameSondId}`, {
    method: "post",
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then((gameData) => {
      dispatch(setGameData(gameData));
    });
};

export const selectAccessToken = (state) => state.app.accessToken;
export const selectGameId = (state) => state.app.gameId;
export const selectUserId = (state) => state.app.userId;
export const selectGames = (state) => state.app.games;

export const isLoggedInSelector = (state) => state.app.accessToken;

export const { login, logout, setGames, setGameId, setUserId, setGameData } =
  appSlice.actions;

export default appSlice.reducer;
