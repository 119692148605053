import React from "react";

const Login = () => {
  return (
    <div className="container mt-5">
      <div className="card p-3" id="player-container">
        <a
          className="btn-spotify"
          href={`${process.env.REACT_APP_SERVER_HOST}/api/login`}
        >
          Login with Spotify
        </a>
      </div>
    </div>
  );
};

export default Login;
